import { css, html, LitElement, PropertyValues } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { property } from "lit/decorators/property.js";
import { styleMap } from "lit/directives/style-map.js";
import { state } from "lit/decorators/state.js";
import debounce from "lodash-es/debounce";
import { AsyncController } from "../../shared/controllers/async-controller";
import { DEFAULT_BASE_URL, SUPPORTED_LANGUAGES, WIDGET_NAMES } from "../../api-sdk/constants";
import "../../shared/components/progress-bar";
import "../../shared/components/promo-pill-label";
import "../../shared/components/notification-blip";
import { defineCustomElement } from "../../shared/defineCustomElement";
import { _loading } from "../../shared/fragments/loading-template";
import { _errorMessage } from "../../shared/fragments/error-message-template";
import { defineCustomText, useCustomText } from "../../shared/fragments/custom-text";
import { cssReset } from "../../shared/css-reset";
import { _cssVariableMap } from "../../shared/fragments/css-variable-map";
import { enforceConfig } from "../../shared/enforce-config";
import { isEqual, logger } from "../../utils";
import { MissingConfig } from "../../utils/beam-errors";
import { TNumericId } from "../../shared/types";
import { createScopedLocalStorage } from "../../utils/local-storage";
import { postChainEligibleNonprofitsForCart, postSelectNonprofit } from "../../api-sdk/v3/routes";
import { LANGUAGES } from "../../api-sdk/types";
import { BeamCartChangeEvent, BeamNonprofitSelectEvent, BeamNonprofitSelectionRemovedEvent } from "../../utils/events";
import { localizeUserString } from "../../shared/localize";
import { progressBarConfigDefaults } from "../../shared/components/progress-bar";
import { cssResponsiveUtils } from "../../shared/responsive";
import { BEAM_CART_COOKIE_NAME, TBeamCartLocalStorage, TCart } from "../../shared/cart-contents";
import { notificationBlipConfigDefaults } from "../../shared/components/notification-blip";
import { promoPillLabelConfigDefaults } from "../../shared/components/promo-pill-label";
import { getBeamCartId, getExternalCartId } from "../../utils/cart";
import { strings } from "./strings";

interface RequiredConfig {
  apiKey: string;
  storeId: TNumericId; // Can be set async by postalCode + countryCode lookup
}

export class BeamSelectNonprofit extends LitElement {
  static tagName = "beam-select-nonprofit";

  @property({ type: String }) public baseUrl: string = DEFAULT_BASE_URL;

  @property({ type: String }) public apiKey?: RequiredConfig["apiKey"];

  // storeId can be omitted if countryCode + postalCode are provided
  @property({ type: Number, reflect: true }) public storeId?: TNumericId;

  @property({ type: String }) public countryCode?: string;

  @property({ type: String }) public postalCode?: string;

  @property({ attribute: false, hasChanged: (a, b) => !isEqual(a, b) }) public cart?: TCart;

  @property({ type: Number, reflect: true }) public selectedNonprofitId: TNumericId | null = null;

  @property({ type: String }) public lang: LANGUAGES = "en";

  @property({ type: Boolean }) public debug = false;

  @property({ type: Boolean }) public draftConfig = false;

  @state() private isMobile = window.innerWidth < 768;

  private enableNonprofitDeselection = false;

  private selectionId?: string;

  private didTryToCreateNewSelectionFromCache = false; // Should only create selection once

  get configLang() {
    return SUPPORTED_LANGUAGES[this.lang] || "en";
  }

  private getChainNonprofits = async () => {
    if (!enforceConfig<RequiredConfig>(["apiKey"], this)) {
      throw new MissingConfig();
    }

    const cart: any = this.cart?.content
      ? {
          schema: this.cart?.schema,
          content: this.cart?.content,
        }
      : undefined;

    // We use a POST method because we send a nested JSON with data that we don't want to serialize/expose in URL
    const res = await postChainEligibleNonprofitsForCart({
      baseUrl: this.baseUrl,
      apiRoot: "/api/v3",
      headers: {
        authorization: `Api-Key ${this.apiKey}`,
      },
      requestBody: {
        storeId: this.storeId,
        widgetName: WIDGET_NAMES.select_nonprofit,
        postalCode: this.postalCode,
        countryCode: this.countryCode,
        version: "1.0.0",
        lang: this.configLang,
        options: {
          config: {
            draftConfig: this.draftConfig,
          },
        },
        cart,
      },
    });

    this.enableNonprofitDeselection = !!res.config.enableNonprofitDeselection;

    // Reset selection if list doesn't include the current selected nonprofit and if it's not set to null
    if (
      this.selectedNonprofitId !== null &&
      this.selectedNonprofitId &&
      !res.nonprofits.map((np) => np.nonprofit.id).includes(this.selectedNonprofitId)
    ) {
      this.selectedNonprofitId = null;
      await this.postSelectNonprofit({ selectedNonprofitId: null });
      this.localStorage.setItem("nonprofit", null);
    }

    // If store ID was not provided, get the store ID from response here
    if (res.store?.id && res.store.id !== this.storeId) {
      this.storeId = res.store.id;
    }

    await this.createNewSelectionForCachedNonprofit();

    this.localStorage.setItemJson("chainNonprofits", {
      createdAt: new Date(),
      data: res,
    });

    return res;
  };

  private postSelectNonprofit = async ({ selectedNonprofitId }: { selectedNonprofitId: TNumericId | null }) => {
    if (!enforceConfig<RequiredConfig>(["apiKey", "storeId"], this)) {
      throw new MissingConfig();
    }

    const cartId = getExternalCartId("cart", { apiKey: this.apiKey });
    const beamCartId = getBeamCartId(BEAM_CART_COOKIE_NAME, { apiKey: this.apiKey });
    const result = await postSelectNonprofit({
      baseUrl: this.baseUrl,
      headers: {
        authorization: `Api-Key ${this.apiKey}`,
      },
      requestBody: {
        nonprofitId: selectedNonprofitId,
        selectionId: this.selectionId,
        storeId: this.storeId,
        cartId: cartId ? cartId : undefined,
        beamCartId: beamCartId ? beamCartId : undefined,
        postalCode: this.postalCode,
        countryCode: this.countryCode,
      },
    });

    this.selectionId = result?.selectionId;
    this.localStorage.setItem("transaction", this.selectionId);
    this.localStorage.setItem("nonprofit", selectedNonprofitId);
    this.localStorage.setItem("nonprofit_selected_at", new Date().toISOString());

    await this.updateComplete;

    const selectedNonprofit = this.getNonprofitById(selectedNonprofitId);

    if (selectedNonprofitId !== null)
      this.dispatchEvent(
        new BeamNonprofitSelectEvent({
          selectedNonprofitId,
          selectionId: this.selectionId,
          nonprofitName: selectedNonprofit?.nonprofit?.name ?? null,
          source: WIDGET_NAMES.select_nonprofit,
        })
      );
    if (selectedNonprofitId && selectedNonprofitId !== null)
      new BeamNonprofitSelectionRemovedEvent({
        newNonprofitId: null,
        selectionId: this.selectionId,
      });
  };

  private nonprofitListDataController = new AsyncController<typeof this.getChainNonprofits>(
    this,
    this.getChainNonprofits
  );

  private selectionDataController = new AsyncController<typeof this.postSelectNonprofit>(
    this,
    this.postSelectNonprofit
  );

  private localStorage = createScopedLocalStorage(this as LitElement & RequiredConfig);

  private getNonprofitById(selectedNonprofitId: number | null) {
    if (!selectedNonprofitId) return null;
    return this.nonprofitListDataController?.data?.nonprofits.find((np) => np.nonprofit.id === selectedNonprofitId);
  }

  private handleCartChange = (evt: BeamCartChangeEvent) => {
    this.cart = evt.detail;
  };

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener(BeamCartChangeEvent.eventName, this.handleCartChange as EventListener);
    window.addEventListener("resize", this.evaluateBreakPoints);
  }

  async firstUpdated(_changedProperties: PropertyValues) {
    await this.restoreStateFromCache();
  }

  async updated(previousPropertyValues: PropertyValues) {
    // Reload nonprofit list on change of any of these props:
    // Also fires on first load as props go from undefined => value
    const requireNewDataProps = ["baseUrl", "storeId", "apiKey", "countryCode", "postalCode", "cart", "lang"];
    for (const prop of requireNewDataProps) {
      if (previousPropertyValues.has(prop)) {
        await this.nonprofitListDataController.exec();
        break;
      }
    }
  }

  disconnectedCallback() {
    window.removeEventListener(BeamCartChangeEvent.eventName, this.handleCartChange as EventListener);
    super.disconnectedCallback();
  }

  private async restoreStateFromCache() {
    try {
      const currentTimestamp = new Date().valueOf();

      this.cart = this.localStorage.getItemJson<TBeamCartLocalStorage>("cart") ?? undefined;

      // Restore the previous nonprofit selection unless it exceeds the defined time limit, in which case clear the selection
      // (Note: localStorage key is called "transaction" for backwards compatibility with legacy SDK)
      const selectionTtl = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
      const selectionCreatedAt = this.localStorage.getItem("nonprofit_selected_at") ?? 0;
      const isSelectionExpired = currentTimestamp > new Date(selectionCreatedAt).valueOf() + selectionTtl;
      if (!isSelectionExpired) {
        this.selectedNonprofitId = parseInt(this.localStorage.getItem("nonprofit") || "") || null;
        this.selectionId = this.localStorage.getItem("transaction") ?? undefined;
      } else if (isSelectionExpired && this.selectedNonprofitId !== null) {
        await this.postSelectNonprofit({ selectedNonprofitId: null });
        this.localStorage.setItem("nonprofit", null);
      }

      // Try to restore nonprofit list if we have data and it's not too old
      // list will continue to refresh async and replace this data when ready
      const { createdAt = 0, data } =
        this.localStorage.getItemJson<{ createdAt: string; data: any }>("chainNonprofits") || {};
      const cacheTtl = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
      const isCacheExpired = currentTimestamp > new Date(createdAt).valueOf() + cacheTtl;
      if (!isCacheExpired) {
        this.nonprofitListDataController.data = data;
        this.nonprofitListDataController.loading = false;
      }
    } catch (err) {
      logger.error(err);
      // ignore cache retrieval error and continue to fetch data
    }
  }

  private async createNewSelectionForCachedNonprofit() {
    if (!enforceConfig<RequiredConfig>(["apiKey"], this)) {
      throw new MissingConfig();
    }
    if (!this.storeId || this.didTryToCreateNewSelectionFromCache) {
      return; // storeId can be set async as a result of calling find nonprofits with postalCode + zipCode
    }
    try {
      // Immediately set flag to prevent more cache restore attempts from triggering
      this.didTryToCreateNewSelectionFromCache = true;

      // Create a new selection ID for the session (e.g., if restoring nonprofit after a completed order)

      if (this.selectedNonprofitId) {
        if (!this.selectionId) {
          await this.selectionDataController.exec({ selectedNonprofitId: this.selectedNonprofitId });
        }
        const selectedNonprofit = this.getNonprofitById(this.selectedNonprofitId);
        this.dispatchEvent(
          new BeamNonprofitSelectEvent({
            selectedNonprofitId: this.selectedNonprofitId,
            selectionId: this.selectionId,
            nonprofitName: selectedNonprofit?.nonprofit?.name,
            source: WIDGET_NAMES.select_nonprofit,
          })
        );
      }
    } catch (err) {
      // Ignore error
    }
  }

  /**
   * Factory for selection event handler
   *
   * Nonprofit selector implements radio-button semantics:
   * * If nothing is selected, tabbing into selector selects first card
   * * Arrow keys changes focus between cards, but doesn't select
   * * Enter/Space sets selection
   * * If a nonprofit is selected, arrow keys change focus AND selection
   * * Click sets selection
   * @param {number} id
   * @param {number} index
   * @param {{id: number}[]} nonprofits
   * @returns {(evt: Event) => void}
   */
  private makeHandleSelect =
    (id: number, index: number, nonprofits: { nonprofit: { id: number } }[]) => async (evt: Event) => {
      const currentId = this.selectedNonprofitId;
      if (evt instanceof KeyboardEvent) {
        let nextFocus = null;
        switch (evt.key) {
          case "ArrowUp":
          case "ArrowLeft":
            if (index === 0) {
              nextFocus = nonprofits[nonprofits.length - 1];
            } else {
              nextFocus = nonprofits[index - 1];
            }
            evt.preventDefault();
            break;
          case "ArrowRight":
          case "ArrowDown":
            if (index === nonprofits.length - 1) {
              nextFocus = nonprofits[0];
            } else {
              nextFocus = nonprofits[index + 1];
            }
            evt.preventDefault();
            break;
          case "Enter":
          case " ":
            evt.preventDefault();
            break; // continue to toggle-selection block below
          default:
            return;
        }
        if (nextFocus) {
          if (currentId != null) {
            this.selectedNonprofitId = nextFocus.nonprofit.id;
          }
          const focusTarget = this.renderRoot.querySelector(`[data-value="${nextFocus.nonprofit.id}"]`) as HTMLElement;
          if (focusTarget !== null) {
            focusTarget.tabIndex = 0;
            focusTarget.focus();
          }
          return;
        }
      }
      // Handle selection with click or Enter/Space key
      const targetEl = evt.currentTarget;
      if (targetEl instanceof HTMLElement) {
        if (currentId === id) {
          if (this.enableNonprofitDeselection) {
            this.selectedNonprofitId = null; // unset
            await this.postSelectNonprofit({ selectedNonprofitId: null });
            this.localStorage.setItem("nonprofit", null);
          } else {
            return;
          }
        } else {
          this.selectedNonprofitId = id;
        }
      }
      await this.selectionDataController.exec({ selectedNonprofitId: this.selectedNonprofitId });
    };

  evaluateBreakPoints = debounce(
    () => {
      this.isMobile = window.innerWidth < 768;
    },
    50,
    { maxWait: 50, leading: true }
  );

  public get cssVariables() {
    const defaults = {
      "--beam-fontFamily": "inherit",
      "--beam-fontStyle": "inherit",
      "--beam-fontSize": "inherit",
      "--beam-textColor": "inherit",
      "--beam-backgroundColor": "inherit",
      ...progressBarConfigDefaults,
      "--beam-SelectNonprofit-title-textAlign": "inherit",
      "--beam-SelectNonprofit-description-textAlign": "inherit",
      "--beam-SelectNonprofit-maxWidth": "800px",
      "--beam-SelectNonprofit-options-marginTop": "0px",
      "--beam-SelectNonprofit-options-iconHeight": "24px",
      "--beam-SelectNonprofit-options-padding": "10px",
      "--beam-SelectNonprofit-options-borderRadius": "0px",
      "--beam-SelectNonprofit-options-borderColor": "currentColor",
      "--beam-SelectNonprofit-options--selected-borderColor": "currentColor",
      "--beam-SelectNonprofit-options-backgroundColor": "transparent",
      "--beam-SelectNonprofit-options-gap": "8px",
      "--beam-SelectNonprofit-options--selected-backgroundColor": "currentColor",
      "--beam-SelectNonprofit-details-marginTop": "10px",
      "--beam-SelectNonprofit-details-borderRadius": "0px",
      "--beam-SelectNonprofit-details-borderColor": "currentColor",
      "--beam-SelectNonprofit-details-backgroundColor": "inherit",
      "--beam-SelectNonprofit-details-padding": "10px",
      ...defineCustomText("--beam-SelectNonprofit-title", {
        fontSize: "1.25em",
        fontWeight: "bold",
      }),
      "--beam-SelectNonprofit-header-inline-lineHeight": "inherit",
      ...defineCustomText("--beam-SelectNonprofit-title-inline", {
        fontWeight: "bold",
      }),
      "--beam-SelectNonprofit-title-inline-textTransform": "none",
      "--beam-SelectNonprofit-title-block-margin-right": "8px",
      ...defineCustomText("--beam-SelectNonprofit-description", {
        marginTop: "0.5em",
      }),
      ...defineCustomText("--beam-SelectNonprofit-description-inline"),
      ...defineCustomText("--beam-SelectNonprofit-details-cause", {
        fontSize: "0.85em",
        fontWeight: "bold",
      }),
      ...defineCustomText("--beam-SelectNonprofit-details-beamAttribution", {
        fontSize: "0.85em",
      }),
      ...defineCustomText("--beam-SelectNonprofit-details-impactDescription", {
        fontSize: "1em",
        marginTop: "10px",
      }),
      "--beam-SelectNonprofit-details-nonprofitName-fontWeight": "bold",
      "--beam-SelectNonprofit-details-nonprofitName-fontStyle": "inherit",
      "--beam-SelectNonprofit-details-fundingProgress-marginTop": "10px",
      ...defineCustomText("--beam-SelectNonprofit-details-fundingProgressLabel", {
        fontSize: "0.85em",
      }),
      ...notificationBlipConfigDefaults,
      ...promoPillLabelConfigDefaults,
      "--beam-SelectNonprofit-promo-block-header-justifyContent": "initial",
    };

    const remoteConfig = this.nonprofitListDataController?.data?.config?.web?.theme || {};

    const config = { ...defaults, ...remoteConfig };

    const serializable = Object.create({
      toCSS() {
        return _cssVariableMap(this as Record<string, string>);
      },
    });

    return Object.assign(serializable, config);
  }

  static styles = [
    cssReset,
    cssResponsiveUtils,
    css`
      :host {
        display: block;
        max-width: var(--beam-SelectNonprofit-maxWidth, 800px);
        font-family: var(--beam-fontFamily);
        font-style: var(--beam-fontStyle);
        font-size: var(--beam-fontSize);
        background-color: var(--beam-backgroundColor);
        color: var(--beam-textColor);
        word-break: normal;
      }

      .details-impactDescription {
        ${useCustomText("--beam-SelectNonprofit-details-impactDescription")}
      }

      .details-impactDescription .nonprofitName {
        font-weight: var(--beam-SelectNonprofit-details-nonprofitName-fontWeight);
        font-style: var(--beam-SelectNonprofit-details-nonprofitName-fontStyle, inherit);
      }

      /* Note: title/description display is responsive */

      .title-block {
        margin-right: var(--beam-SelectNonprofit-title-block-margin-right);
        ${useCustomText("--beam-SelectNonprofit-title")}
        text-align: var(--beam-SelectNonprofit-title-textAlign);
      }

      .header-inline {
        line-height: var(--beam-SelectNonprofit-header-inline-lineHeight);
      }

      .title-inline {
        font-size: var(--beam-SelectNonprofit-title-inline-fontSize);
        font-weight: var(--beam-SelectNonprofit-title-inline-fontWeight);
        color: var(--beam-SelectNonprofit-title-inline-color);
        font-family: var(--beam-SelectNonprofit-title-inline-fontFamily);
        text-transform: var(--beam-SelectNonprofit-title-inline-textTransform);
      }

      .description-inline {
        font-family: var(--beam-SelectNonprofit-description-inline-fontFamily);
        font-weight: var(--beam-SelectNonprofit-description-inline-fontWeight);
        color: var(--beam-SelectNonprofit-description-inline-color);
        text-transform: var(--beam-SelectNonprofit-description-inline-textTransform);
        font-size: var(--beam-SelectNonprofit-description-inline-fontSize);
      }

      .description {
        ${useCustomText("--beam-SelectNonprofit-description")}
        text-align: var(--beam-SelectNonprofit-description-textAlign);
      }

      .block-header-promo-pill-container {
        display: flex;
        align-items: center;
        justify-content: var(--beam-SelectNonprofit-promo-block-header-justifyContent);
      }

      .block-header-promo-pill-container-responsive {
        flex-direction: column;
        align-items: flex-start;
      }
      .block-header-promo-pill-container-responsive beam-promo-info-pill {
        order: -1;
      }
    `,
  ];

  protected render() {
    const { selectedNonprofitId } = this;
    const { data, loading } = this.nonprofitListDataController;

    if (loading && !data) {
      // TODO: better loading UI
      return _loading(); // TODO: css theme first
    }
    if (this.nonprofitListDataController.error) {
      if (this.debug) {
        return _errorMessage({ error: this.nonprofitListDataController.error });
      }
      return "";
    }
    if (this.selectionDataController.error) {
      if (this.debug) {
        return _errorMessage({ error: this.selectionDataController.error });
      }
      // do not show error screen for interactive errors by default
    }
    const nonprofits = data?.nonprofits || [];
    const selectedNonprofit = nonprofits.find((np) => np.nonprofit.id === selectedNonprofitId) || null;
    const shouldUseNewPromoUI = !!data?.config?.web?.promo;
    const hasNonprofitWithInactivePromo = nonprofits.some((nonprofit) => !nonprofit.promo || !nonprofit.promo.isActive);
    const defaultHeader = html`<h3 class="title-block d-none d-lg-block" part="title" id="beam-SelectNonprofit-title">
      ${localizeUserString(this.configLang, data?.config?.web?.title || "") || strings[this.configLang].ctaTitle()}
    </h3>`;
    const renderHeader = () => {
      return shouldUseNewPromoUI
        ? html`<div
            class="${this.isMobile
              ? "block-header-promo-pill-container-responsive"
              : "block-header-promo-pill-container"}"
            style="${this.isMobile ? "display: flex" : ""}"
          >
            ${defaultHeader}
            <beam-promo-info-pill .promo=${data?.config?.web?.promo}></beam-promo-info-pill>
          </div>`
        : defaultHeader;
    };
    return html`
      <style>
        :host {
          ${this.cssVariables.toCSS()}
        }
      </style>
      <div part="heading">
        ${renderHeader()}
        <p class="description" part="description">
          <span class="d-none d-lg-inline">
          ${
            shouldUseNewPromoUI
              ? html`<span style="font-weight:bold">
                    ${localizeUserString(this.configLang, data?.config?.web?.promoDescriptionPrefix || "") ||
                    strings[this.configLang].ctaPromoPrefixMessage()}
                  </span>
                  <span>
                    ${localizeUserString(this.configLang, data?.config?.web?.promoDescription || "") ||
                    strings[this.configLang].ctaPromoMessage()}
                  </span>`
              : html`<span>
                  ${localizeUserString(this.configLang, data?.config?.web?.description || "") ||
                  strings[this.configLang].ctaMessage()}
                </span>`
          }
          </span>
          <div class="d-lg-none header-inline">
            <span class="title-inline" part="title">
              ${
                (localizeUserString(this.configLang, data?.config?.web?.title || "") ||
                  strings[this.configLang].ctaTitle()) + strings[this.configLang].inlineSeparator()
              }
            </span>
            <span class="description-inline" part="description">
            ${
              shouldUseNewPromoUI
                ? html`<span style="font-weight:bold">
                      ${localizeUserString(this.configLang, data?.config?.web?.promoDescriptionPrefix || "") ||
                      strings[this.configLang].ctaPromoPrefixMessage()}
                    </span>
                    <span>
                      ${localizeUserString(this.configLang, data?.config?.web?.promoDescription || "") ||
                      strings[this.configLang].ctaPromoMessage()}
                    </span>`
                : html`<span
                    >${localizeUserString(this.configLang, data?.config?.web?.description || "") ||
                    strings[this.configLang].ctaMessage()}
                  </span>`
            }
          </div>
        </p>
      </div>
      <div
        class="options"
        part="options"
        role="radiogroup"
        aria-labelledby="beam-SelectNonprofit-title"
        style="display: flex; gap: var(--beam-SelectNonprofit-options-gap); margin: 10px 0 0 0;"
      >
        ${repeat(
          nonprofits,
          (i) => i.nonprofit.id,
          ({ nonprofit, promo }, index) => {
            const isSelected = selectedNonprofitId === nonprofit.id;
            const isFocusable = isSelected || (selectedNonprofit == null && index === 0);
            const shouldAddPromoBlip = promo?.isActive && data?.config.web.promo && hasNonprofitWithInactivePromo;
            return html`
              <div
                class="option"
                part="option"
                role="radio"
                tabindex="${isFocusable ? 0 : -1}"
                data-value=${nonprofit.id}
                aria-checked=${isSelected}
                @click=${this.makeHandleSelect(nonprofit.id, index, nonprofits)}
                @keydown=${this.makeHandleSelect(nonprofit.id, index, nonprofits)}
                aria-label="${localizeUserString(this.configLang, nonprofit.cause || "")}"
                style="${styleMap({
                  cursor: "pointer",
                  flex: "1",
                  textAlign: "center",
                  lineHeight: "1",
                  marginTop: "var(--beam-SelectNonprofit-options-marginTop, 0px)",
                  padding: "var(--beam-SelectNonprofit-options-padding, 10px)",
                  borderWidth: "var(--beam-SelectNonprofit-options-borderWidth, 1px)",
                  borderStyle: "solid",
                  borderRadius: "var(--beam-SelectNonprofit-options-borderRadius, 0)",
                  borderColor: isSelected
                    ? nonprofit.causeColor || "var(--beam-SelectNonprofit-options--selected-borderColor, currentColor)"
                    : "var(--beam-SelectNonprofit-options-borderColor, currentColor)",
                  backgroundColor: isSelected
                    ? nonprofit.causeColor ||
                      "var(--beam-SelectNonprofit-options--selected-backgroundColor, currentColor)"
                    : "var(--beam-SelectNonprofit-options-backgroundColor, transparent)",
                })}"
              >
                <img
                  src="${isSelected ? nonprofit.causeIconSelectedUrl : nonprofit.causeIconUrl}"
                  alt=""
                  role="presentation"
                  style="
                        height: var(--beam-SelectNonprofit-options-iconHeight, 24px);
                        user-select: none;
                        vertical-align: -webkit-baseline-middle;
                    "
                />
                ${shouldAddPromoBlip ? html`<beam-notification-blip></beam-notification-blip>` : html``}
              </div>
            `;
          }
        )}
      </div>
      ${
        selectedNonprofit != null
          ? html`
              <div
                class="details"
                part="details"
                style="
              border: 1px solid var(--beam-SelectNonprofit-details-borderColor);
              border-radius: var(--beam-SelectNonprofit-details-borderRadius);
              background-color: var(--beam-SelectNonprofit-details-backgroundColor);
              padding: var(--beam-SelectNonprofit-details-padding);
              margin-top: var(--beam-SelectNonprofit-details-marginTop);
            "
                aria-label="Funding information for selected nonprofit ${selectedNonprofit.nonprofit
                  ?.name}. Powered by Beam"
              >
                <div
                  style="display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap-reverse"
                >
                  <span
                    class="details-cause"
                    style="flex: 0 1; white-space: nowrap; ${useCustomText("--beam-SelectNonprofit-details-cause")}"
                  >
                    ${selectedNonprofit?.promo?.isActive && hasNonprofitWithInactivePromo
                      ? data?.config.web.promo?.["promo-cause-alt-text"] || selectedNonprofit.nonprofit.cause
                      : localizeUserString(this.configLang, selectedNonprofit.nonprofit.cause || "")}
                  </span>
                  <div aria-hidden="true">
                    <span
                      class="details-beamAttribution"
                      aria-hidden="true"
                      style="flex: 0 1; white-space: nowrap; ${useCustomText(
                        "--beam-SelectNonprofit-details-beamAttribution"
                      )}"
                    >
                      ${strings[this.configLang].beamAttribution()}
                    </span>
                  </div>
                </div>
                <p class="details-impactDescription">
                  ${unsafeHTML(localizeUserString(this.configLang, selectedNonprofit.impact.description || ""))}
                </p>
                <div
                  style="display: flex; margin-top: var(--beam-SelectNonprofit-details-fundingProgress-marginTop); align-items: center;"
                >
                  <beam-progress-bar
                    value="${selectedNonprofit.impact.goalProgressPercentage}"
                    style="flex: 1 0;"
                  ></beam-progress-bar>
                  <span
                    class="details-fundingProgressLabel"
                    style="${useCustomText(
                      "--beam-SelectNonprofit-details-fundingProgressLabel"
                    )} white-space: nowrap; text-align: right; flex: 0 1; margin-left: 15px;"
                  >
                    ${localizeUserString(this.configLang, selectedNonprofit.impact.goalProgressText)}
                  </span>
                </div>
              </div>
            `
          : ""
      }
    `;
  }
}

defineCustomElement(BeamSelectNonprofit);

declare global {
  interface HTMLElementTagNameMap {
    "beam-select-nonprofit": BeamSelectNonprofit;
  }
}
